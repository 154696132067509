<template>
  <div>
    <form class="mt-4" ref="loginForm" @submit.prevent="onSubmit">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="email">Email</label>
          <div class="input-with-icon">
            <!-- <span class="icon is-small is-left" style="top: 18px;">
              <i class="fas fa-envelope"></i>
            </span> -->
            <input
              type="email"
              class="form-control mb-0"
              id="email"
              name="email"
              @keydown="preventLeadingSpace($event, 'email')"
              v-model="user.email"
              placeholder="xyz@abc.com"
              maxlength="80"
            />
          </div>
            
        </div>
        <div class="form-group col-md-12 mb-3">
          <label for="password">Password</label>
          <div class="input-with-icon">
            <input type="text" v-if="showPassword" @input="validatePassword"
              @keydown="preventLeadingSpace($event, 'password')" class="form-control mb-0" id="password" name="password"
              v-model="user.password" placeholder="Password" maxlength="20" />
            <input type="password" v-else @input="validatePassword" @keydown="preventLeadingSpace($event, 'password')"
              class="form-control mb-0" id="password" name="password" v-model="user.password" placeholder="Password"
              maxlength="20" />
            <span class="icon is-small is-right" @click="passwordtoggleShow">
              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
            </span>
          </div>
          <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
        </div>
        <div class="row" style="width: 100%;">
          <div class="col-lg-8">
            <div class="d-inline-block w-100 mt-2 mb-3 ml-3">
              <button :disabled="isSubmitting" type="submit" class="btn btn-primary float-left mr-2">
                {{ isSubmitting ? 'Sign In' : 'Sign In' }}
              </button>
              <button type="reset" class="btn btn-primary float-left">Cancel</button>
            </div>
          </div>
          <div class="col-lg-4">
              <router-link to="/auth/recover-password" class="forgot-password-link">
                <h6>Forgot password?</h6>
              </router-link>
         
          </div>
        </div>
      
       
        <div>
          <span class="dark-color ml-3"> Don't have an account? </span>
          <a href="/auth/sign-up" class="iq-waves-effect pr-4"> Sign up </a>
        </div>
      </div>
    </form>
  </div>
</template>



<script>
import axios from "axios";
import constant, { encryptToken, toastMessage } from "@/config/constant";
import "jquery-validation";

export default {
  name: "SignInForm",
  data() {
    return {
      isSubmitting: false, // Define isSubmitting in the data object
      showPassword: false,
      user: {
        email: "",
        password: "",
      },
      message: "",
      passwordError: "",
    };
  },
  mounted() {
    //Form Validation
    $(this.$refs.loginForm).validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
      },
      messages: {
        email: {
          required: "Email is required",
        },
      },
    });
  },
  methods: {
    // First letter space not allowed
    preventLeadingSpace(event, field) {
      if (event.key === ' ' && (event.target.selectionStart === 0 || this.user[field] === '')) {
        event.preventDefault();
      } else {
        this.user[field] = event.target.value.trim();
      }
    },
    // Strong password
    validatePassword() {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,}$/;
      if (!regex.test(this.user.password)) {
        this.passwordError = "Password should be a minimum of 5 characters, including at least 1 letter, 1 number, and 1 special character";
      } else {
        this.passwordError = '';
      }
    },
    //Password eye icon show and hide
    passwordtoggleShow() {
      this.showPassword = !this.showPassword;
    },
    // User Login
    onSubmit() {
      // First, validate the password
      this.validatePassword();
      // If there's an error, don't proceed
      if (this.passwordError) {
        return;
      }
      if (!$(this.$refs.loginForm).valid()) return;
      this.isSubmitting = true; // Enable the submit button
      axios
        .post(
          constant.API_BASE_URL + "login/",
          {
            email: this.user.email,
            password: this.user.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const token = response.data.access;
          if (token) {
            localStorage.setItem("token", encryptToken(token));
            this.$router.push({ name: "dashboard.webmonitoring" });
          } else {
            this.$router.push({ name: "sign-in" });
          }
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.icon = "error";
          toastMessage(this.icon, this.message);
        })
        .finally(() => {
          this.isSubmitting = false; // Enable submit button
        });
      this.message = "";
    },
  },
};
</script>
