<template>
  <div>
    <img :src="logo" alt="Logo" class="logo">
    <h1 class="mb-0 text-center">Sign In</h1>
    <p class="text-center text-dark">Enter your email address and password to access admin panel.</p>
    <sign-in-form></sign-in-form>
  </div>
</template>

<script>
import SignInForm from "./Forms/SignInForm";

export default {
  name: "SignIn",
  components: { SignInForm },
  data() {
    return {
      logo: require('@/assets/images/Logo-C9lab eyeWhite.png')
    }
  }
};
</script>

<style scoped>
.logo {
  display: none; /* Initially hide the logo */
}

@media screen and (max-width: 768px) {
  .logo {
    display: block; /* Show the logo on small screens */
    width: 30%; /* Adjust the width as needed */
    margin: auto; /* Center the logo */
  }
}
</style>
